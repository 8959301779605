import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { ClienteService } from 'app/services/cliente.service';
import { LocationService } from 'app/services/location.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import * as rut from '@fdograph/rut-utilities';
import { Observable, of, ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { DocsService } from 'app/services/docs.service';
@Component({
  selector: 'app-dialog-create-update-interaction',
  templateUrl: './dialog-create-update-interaction.component.html',
  styleUrls: ['./dialog-create-update-interaction.component.scss']
})
export class DialogCreateUpdateInteractionComponent implements OnInit {

  @ViewChild('btnCreateCaso') btnCreateCaso: MatButton;

  flexibleAttributesForm: UntypedFormGroup;
  notasForm: UntypedFormGroup;
  formCustomer: UntypedFormGroup;
  formContact: UntypedFormGroup;
  //
  filteredTipo: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  tipoFilterCtrl: FormControl<string>= new FormControl<string>('');
  protected _onDestroy = new Subject<void>();
  //
  t1List: any[];
  t2List: any[];
  flexibleAttributes: any[];
  flexibleAttributesFiltered: any[]=[];
  flexibleAttributesValue: any[]=[];
  flexibleAttributesToCreate: any[] = [];
  flexibleAttributesValidate: false;
  t2ListFiltered: any[];
  vt2Selected: any;
  status: any[];

  formAddress: UntypedFormGroup;
  ciudadList: any=[];
  ciudadListFiltered: any;
  comunaList: any=[];
  comunaListFiltered: any;
  regionList: any=[];

  flagSgtm : boolean = false;
  segmento = null;

  /***********/
  // folioCtrl = new FormControl();
  /***********/

  interaction: any={};
  uuidCmEmployee =localStorage.getItem('uuidEmployee');

  isDisabledNote    = false;
  isDisabledCase    = false;
  isDisabledAddress = false;

  rutCustomerFormat =null
  txtError="";
  ///////////////////////////////////////////////
  flagShowFancyName :Boolean= false;
  contacMail        = null;                               
  contacRut         = null;                         
  contacBirthday    = null;                         
  contacFirstName   = null;                  
  contacFirstName2  = null;                  
  contacLastName    = null;                  
  contacLastName2   = null;   
  dueDates : any = [
                       {id:5,val:"5"}
                      ,{id:10,val:"10"}
                      ,{id:15,val:"15"}
                      ,{id:20,val:"20"}
                      ,{id:25,val:"25"}
                      ,{id:30,val:"30"}
                      ,{id:45,val:"45"}
                      ,{id:60,val:"60"}
                    ]
  customerValue : any =[]
  customerValueDefault =0;
  customerContactType : any =[]
  //
  authDTE      : any = {}
  nacionalidad : any =[]
  tipoDocID    : any =[]
  glsNacionalidad :string=null
  emisorData = undefined;
  userData =  {
                   login        : ''
                  ,firstName    : ''
                  ,lastName     : ''
                  ,rutEmpresa   : ''
                  ,logoEmpresa  : ''
                  ,pin          : ''
                  ,envirometDTE : ''
                  ,envirometBOL : ''
                  ,envirometTKT : ''             
                  }
//
//==
arrTpoNota = [
               {"tipo":"F","gls":"Flash"}
              ,{"tipo":"Y","gls":"Recordatorio"}
              ,{"tipo":"N","gls":"Nota"}
            ]
valTpoNota ='N'
//==
addressType     = []
showAddressType = false
glsAddressType  = ''
//==
//////////////////////////////////////////////
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _dialogRef: MatDialogRef<DialogCreateUpdateInteractionComponent>,
    private _fb: FormBuilder,
    private _clienteService: ClienteService,
    private _locationService: LocationService,
    private _fuseConfirmationService: FuseConfirmationService,
    private _docsServ: DocsService,
  ) {
    this.notasForm = this._fb.group({
      txt: ['', [Validators.required, Validators.maxLength(500)]],
      dateSchedule: [''],
      valTpoNota: ['N',[Validators.required]],

    });
    this.formAddress = this._fb.group({
      aliasCustomerAddress     : [null, [Validators.required]],
      address1                 : [null, [Validators.required]],
      address2                 : [null, [Validators.required]],
      address3                 : ['N/A', [Validators.required]],
      phoneNumber              : [null, [Validators.required]],
      postalCode               : [null, [Validators.required]],
      deliveryNotes            : ['', [Validators.required]],
      idRegion                 : ['', [Validators.required]],
      idCiudad                 : ['', [Validators.required]],
      idComuna                 : ['', [Validators.required]],
      addressDefault           : ['', [Validators.required]],
      addressType              : ['', [Validators.required]],
      idCrmCustomerAddressType : [1, [Validators.required]]
    })
    

//txt
//Validators.minLength(5),Validators.maxLength(10)
//Validators.maxLength(10)
//number
//[Validators.min(5), Validators.max(10)]

this.formCustomer = this._fb.group({
                                      uuidCustomer     : null
                                    ,appToken         : null
                                    ,idCmCustomerValue: [0, [Validators.required]]   
                                    ,flagNewsletter   : [true]                      
                                    ,website          : ""           
                                    ,maxPaymentDays   : [5, [Validators.required]]          
                                    ,flagBloqCustomer : [false]            
                                    ,flagBloqSupplier : [false]        
                                    ,flagCustomer     : [true]           
                                    ,flagSupplier     : [false]           
                                    ,flagEmployee     : [false]           
                                    ,flagAssociate    : [false]           
                                    ,flagDistributor  : [false]           
                                    ,creditLimit      : [50000, [Validators.required,Validators.min(1000), Validators.max(99999999)]]           
                                    ,customerCsat     : 5 
                                    ,rut              : ""           
                                    ,razonSocial      : [null, [Validators.required,Validators.minLength(3),Validators.maxLength(100)]]        
                                    ,customerFancyName: [null,[Validators.minLength(3),Validators.maxLength(100)]]                 
                                    ,giroImpreso      : [null,[Validators.minLength(3),Validators.maxLength(40)]]      
                                    ,direccion        : ""      
                                    ,idRegion         : [null, [Validators.required]]
                                    ,idCiudad         : [null, [Validators.required]]
                                    ,idComuna         : [null, [Validators.required]]                  
                                    ,emailDte         : [null, [Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9_.+-]+\.[a-zA-Z0-9_.+-]{2,4}$'),Validators.maxLength(80)]]                 
                                    ,contactoDte      : ""                  
                                    ,telefonoDte      : ""                  
                                    ,contacMail       :  [null]//[null, [Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9_.+-]+\.[a-zA-Z0-9_.+-]{2,4}$')]]         
                                    ,contacPhone      :  [null]//[null]                   
                                    ,contacRut        :  [null]//[null, [Validators.required]]                       
                                    ,contacBirthday   : ""                         
                                    ,contacFirstName  : ""                  
                                    ,contacFirstName2 : ""                  
                                    ,contacLastName   : ""                  
                                    ,contacLastName2  : ""      
                                    ,flagShowFancyName: [false]   
                                    ,codNacionalidad  : ['997']   
                                    ,tipoDocId        : ['2']   
                                    ,flagExtranjero   : [false]   
                                    })

      this.formContact = this._fb.group({
        uuidCustomer              : [null]
       ,appToken                  : [null]
       ,uuidCrmCustomerContact    : [null]
       ,uuidCrmCustomer           : [null]
       ,idCrmCustomerContactType  : [null,[Validators.required]]
       ,mail                      : [null,[Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9_.+-]+\.[a-zA-Z0-9_.+-]{2,4}$'),Validators.maxLength(100)]]                 
       ,phone                     : [null,[Validators.required,Validators.maxLength(50)]]
       ,legalNumber               : [null]//,[Validators.required,Validators.maxLength(20)]
       ,firstName                 : [null,[Validators.required,Validators.maxLength(50)]]
       ,firstName2                : [null,[                    Validators.maxLength(50)]]
       ,lastName                  : [null,[Validators.required,Validators.maxLength(50)]]
       ,lastName2                 : [null,[                    Validators.maxLength(50)]]
       ,extraData                 : ['{}',[Validators.required]]
       ,birthday                  : [null] 
   })   
   
   this.emisorData = JSON.parse(localStorage.getItem('emisorData'));
   //==
   var tmp;
   tmp =  (JSON.parse(localStorage.getItem('ssoData')))
   this.userData = {
                        login        : tmp.user.login
                       ,firstName    : tmp.user.firstName
                       ,lastName     : tmp.user.lastName
                       ,pin          : this.emisorData.usrData[0].PIN
                       ,logoEmpresa  :  null
                       ,rutEmpresa   : localStorage.getItem('rutEmpresa')
                       ,envirometDTE : this.emisorData.emisorData[0].COD_ENVIROMENT_DTE
                       ,envirometBOL : this.emisorData.emisorData[0].COD_ENVIROMENT_BOL
                       ,envirometTKT : this.emisorData.emisorData[0].COD_ENVIROMENT_TKT
                   }

  }
//#########################################	
setAddressType(e){
	var tmp:any = this.addressType.filter(i => i.ID_CRM_CUSTOMER_ADDRESS_TYPE == e)[0]
	if(tmp.FLAG_ADDRESS_3 == 'Y'){
		this.showAddressType = true; 
		this.formAddress.get('address3').setValue(null);
	}else{ 
		this.showAddressType = false;
		this.formAddress.get('address3').setValue('N/A');
	}
	this.glsAddressType  = tmp.GLS_CUSTOMER_ADDRESS_TYPE
}
//#########################################	
//funciones de region, ciudad, comuna
async getLocation(form): Promise<void>{
  this.comunaList = await this._locationService.getAllComunas();
  this.regionList = await this._locationService.getAllRegiones();
  this.ciudadList = await this._locationService.getAllCiudades();
  // console.log(x, y, z);
  // console.log(this.comunaList, this.regionList, this.ciudadList);
  this.comunaListFiltered = [];
  this.ciudadListFiltered = [];
  if(form == 'customer'){
    this.formCustomer.get('idCiudad').setValue(null);
    this.formCustomer.get('idCiudad').disable();
    this.formCustomer.get('idComuna').setValue(null);
    this.formCustomer.get('idComuna').disable();
  }
};
//==
setCiudad(form,idRegion){
    if(form == 'customer'){
      this.ciudadListFiltered = this.ciudadList.filter((item) => item.ID_REGION == idRegion);
      this.formCustomer.get('idCiudad').setValue(null);
      this.formCustomer.get('idCiudad').enable();
      this.formCustomer.get('idComuna').setValue(null);
      this.formCustomer.get('idComuna').disable();
    }
}
//==
setComuna(form,idCiudad){
  if(form == 'customer'){
    this.comunaListFiltered = this.comunaList.filter((item) => item.ID_CIUDAD == idCiudad);
    this.formCustomer.get('idComuna').setValue(null);
    this.formCustomer.get('idComuna').enable();
  }
}
//######################################################
//######################################################
async getCustomerValue(){
 let tmp  = await this._clienteService.getCustomerValue();
 this.customerValue = tmp;
 let tmp2 = tmp.filter(item => item.FLAG_DEFAULT == 'Y')
 this.customerValueDefault = tmp2[0].ID_CM_CUSTOMER_VALUE
 this.formCustomer.get('idCmCustomerValue').setValue(this.customerValueDefault);

}
//######################################################
//######################################################
chkRut(strRut,contact:boolean  =false){
if(strRut == '11111111-1' && contact == true){
  return {status:true,srtRut:'11.111.111-1',srtRutClean:'11111111-1'}
}
else{
  if(strRut){
    let tmpRut = strRut.split("-")
    if(tmpRut[1] == 'k' || tmpRut[1] == 'K') strRut =  tmpRut[0] +'-K'
    ///////////////
        if(rut.validateRut(strRut) == true)
          return {status:true,srtRut:rut.formatRut(strRut, rut.RutFormat.DOTS_DASH),srtRutClean:rut.formatRut(strRut, rut.RutFormat.DASH)}
        else
          return {status:false,srtRut:null}
    }
return {status:false,srtRut:null,srtRutClean:null}
  }
}
//######################################################
//######################################################
async setCustomer(segmento){
  if(this.segmento == 'E'){
    this.formCustomer.get('giroImpreso').setValidators    ([Validators.required,Validators.maxLength(40)]);
    this.formCustomer.get('direccion').setValidators      ([Validators.required,Validators.maxLength(70)]);       
    this.formCustomer.get('idRegion').setValidators       ([Validators.required]);
    this.formCustomer.get('idCiudad').setValidators       ([Validators.required]);
    this.formCustomer.get('idComuna').setValidators       ([Validators.required]);
    this.formCustomer.get('contacRut').setValidators      ([Validators.required,Validators.maxLength(20)]);
    this.formCustomer.get('website').setValidators        ([Validators.required,Validators.maxLength(100)]);
    this.formCustomer.get('contacFirstName').setValidators([Validators.required,Validators.maxLength(50)]);
    this.formCustomer.get('contacLastName').setValidators ([Validators.required,Validators.maxLength(50)]);
    //////////////////////////////////////
    let rzs = await this._clienteService.getCustomerData(this.formCustomer.value.rut)
    if(rzs.data.rzs != null){
      this.formCustomer.get('razonSocial').setValue(rzs.data.rzs);
      this.formCustomer.get('website').setValue(rzs.data.url);
      this.formCustomer.get('emailDte').setValue(rzs.data.mailDTE);
    }
    /////////////////////////////////////
  
  }
  else{
    this.formCustomer.get('giroImpreso').setValue('Sin giro');
    this.formCustomer.get('direccion').setValidators([]);       
    this.formCustomer.get('idRegion').setValidators([]);
    this.formCustomer.get('idCiudad').setValidators([]);
    this.formCustomer.get('idComuna').setValidators([]);
    this.formCustomer.get('contacRut').setValue(this.rutCustomerFormat);
    this.formCustomer.get('website').setValue('www.easybill.cl');
    this.formCustomer.get('contacRut').disable();
  
  }
}
//######################################################
//######################################################
setContactData(type,val){
var tmp;

if(this.segmento == 'P'){
  if(type == 'rzn'){
      tmp =  this.formCustomer.value.razonSocial.split(' ');
      if(tmp.length == 1){
        this.formCustomer.get('contacFirstName').setValue(tmp[0]);
        this.formCustomer.get('contacFirstName2').setValue(null);
        this.formCustomer.get('contacLastName').setValue(null);
        this.formCustomer.get('contacLastName2').setValue(null);
      }
      if(tmp.length == 2){
        this.formCustomer.get('contacFirstName').setValue(tmp[0]);
        this.formCustomer.get('contacFirstName2').setValue(null);
        this.formCustomer.get('contacLastName').setValue(tmp[1]);
        this.formCustomer.get('contacLastName2').setValue(null);        
      }      
      if(tmp.length == 3){
        this.formCustomer.get('contacFirstName').setValue(tmp[0]);
        this.formCustomer.get('contacFirstName2').setValue(null);
        this.formCustomer.get('contacLastName').setValue(tmp[1]);
        this.formCustomer.get('contacLastName2').setValue(tmp[2]);         
      }      
      if(tmp.length == 4){
        this.formCustomer.get('contacFirstName').setValue(tmp[0]);
        this.formCustomer.get('contacFirstName2').setValue(tmp[3]);
        this.formCustomer.get('contacLastName').setValue(tmp[1]);
        this.formCustomer.get('contacLastName2').setValue(tmp[2]);             
      }      
      if(tmp.length > 4){
        this.formCustomer.get('contacFirstName').setValue(tmp[0]);
        this.formCustomer.get('contacFirstName2').setValue(tmp[4]);
        this.formCustomer.get('contacLastName').setValue(tmp[1]);
        this.formCustomer.get('contacLastName2').setValue(tmp[2]+' '+tmp[3]);             
      }        
  }
  if(type == 'mail'){
    this.formCustomer.get('contacMail').setValue(this.formCustomer.value.emailDte);
  }
}

}
//######################################################
//######################################################
async chkSgtmExtranjero(){
  var rutEx = await this._clienteService.getRutExtranjero('CL')
  console.log(rutEx.rut)
  this.formCustomer.get('rut').setValue(rutEx.rut);
  this.formCustomer.get('flagExtranjero').setValue(true);
  this.chkSgtm()
  /*await this._clienteService.getRutExtranjero('CL').then(
    async function(val) {
        // ----------------------------------------------------------------
          console.log(val.rut)
          this.formCustomer.get('rut').setValue(val.rut);
        // ----------------------------------------------------------------
      })
      .catch(
           // Registrar la razón del rechazo
          function(reason) {
          // ----------------------------------------------------------------
          console.log(reason)
          console.log("No registado!!!")
          console.log("#############")
          // ----------------------------------------------------------------                                                            
          });  
          */      
}
//######################################################
//######################################################
async chkSgtm(){
  const $this = this;
var rutTmp =  this.formCustomer.value.rut
var rutOK =this.chkRut(rutTmp)
var rutUnformat,rutDots
  if(rutOK.status == true){
    //====================
    this.rutCustomerFormat = rutOK.srtRut
    this.flagSgtm = true;
    rutUnformat = rut.formatRut(rutOK.srtRut,rut.RutFormat.DASH)
    rutDots     = rutUnformat.split('-')
    if(Number(rutDots[0]) > 50000000) {
        this.segmento = 'E'; 
    }else{
        if(Number(rutDots[0]) > 45000000){
          this.segmento = 'X';
        }else{
          this.segmento = 'P';
        }
        
    }
    this.setCustomer(this.segmento )
    this.formCustomer.get('rut').setValue(rutUnformat);
    this.formCustomer.get('rut').disable;      
    //====================    
    //valido si el Rut ya esta creado
     await this._clienteService.getClienteByRut(rutUnformat).then(
        async function(val) {
            // ----------------------------------------------------------------
            if($this.data.type == 'create'){
                    const dialogRef = $this._fuseConfirmationService.open({
                      title: `Rut Creado, <b>${$this.rutCustomerFormat}</b><br>${val.customer[0].RAZON_SOCIAL}  `,
                      message: `Modificar al cliente`,
                      icon: {
                          show: true,
                          name: 'assignment_turned_in',
                          color: 'primary'
                      },
                      actions: {
                          confirm: {
                              label: 'Aceptar',
                              color: 'primary'
                          },
                          cancel: {
                              show : false,
                              label : "Cancel"
                            }
                      },
                      dismissible: true    
                    });
                    dialogRef.afterClosed().subscribe(async (result) => {
                      $this.loadCustomerData(val.customer[0])
                    });
              }
              else{
                $this.loadCustomerData(val.customer[0])
              }
            return true
            // ----------------------------------------------------------------
        })
        .catch(
             // Registrar la razón del rechazo
            function(reason) {
            // ----------------------------------------------------------------
            console.log(reason)
            console.log("No registado!!!")
            console.log("#############")
            // ----------------------------------------------------------------                                                            
            });
    //====================        
    }
  else{
    //====================
          const dialogRef = this._fuseConfirmationService.open({
            title: `Error `,
            message: `Rut no valido: <b>${rut.formatRut(rutTmp, rut.RutFormat.DOTS_DASH)}</b>`,
            icon: {
                show: true,
                name: 'delete',
                color: 'warn'
            },
            actions: {
                confirm: {
                    label: 'Aceptar',
                    color: 'warn'
                },
                cancel: {
                    show : false,
                    label : "Cancel"
                  }
            },
            dismissible: true    
        });
        dialogRef.afterClosed().subscribe(async (result) => {
          this.formCustomer.get('rut').setValue(null);
        });
    //====================        
  }
}
//######################################################
//######################################################
cleanData(txt){
  if(txt.toUpperCase() == 'NULL')
    return ''
  else
    return txt
}
//######################################################
//######################################################
loadCustomerData(data){


  this.formCustomer.patchValue({
     uuidCustomer     : data.UUID_CRM_CUSTOMER
    ,appToken         : null
    ,idCmCustomerValue: data.ID_CM_CUSTOMER_VALUE
    ,flagNewsletter   : data.FLAG_NEWSLETTER    === 'Y' ? true : false
    ,website          : data.WEBSITE
    ,maxPaymentDays   : data.MAX_PAYMENT_DAYS      
    ,flagBloqCustomer : data.FLAG_BLOQ_CUSTOMER === 'Y' ? true : false
    ,flagBloqSupplier : data.FLAG_BLOQ_SUPPLIER === 'Y' ? true : false
    ,flagCustomer     : data.FLAG_CUSTOMER      === 'Y' ? true : false
    ,flagSupplier     : data.FLAG_SUPPLIER      === 'Y' ? true : false
    ,flagEmployee     : data.FLAG_EMPLOYEE      === 'Y' ? true : false
    ,flagAssociate    : data.FLAG_ASSOCIATE     === 'Y' ? true : false
    ,flagDistributor  : data.FLAG_DISTRIBUTOR   === 'Y' ? true : false
    ,creditLimit      : data.CREDIT_LIMIT           
    ,customerCsat     : data.CUSTOMER_CSAT
    ,rut              : data.RUT
    ,razonSocial      : data.RAZON_SOCIAL        
    ,customerFancyName: data.CUSTOMER_FANCY_NAME
    ,giroImpreso      : this.cleanData(data.GIRO_IMPRESO) //data.GIRO_IMPRESO
    ,direccion        : this.cleanData(data.DIRECCION)
    ,idRegion         : data.ID_REGION
    ,idCiudad         : data.ID_CIUDAD
    ,idComuna         : data.ID_COMUNA                 
    ,emailDte         : this.cleanData(data.EMAIL_DTE)                
    ,contactoDte      : data.CONTACTO_DTE
    ,telefonoDte      : data.TELEFONO_DTE
    //
    ,codNacionalidad  : data.COD_NACIONALIDAD
    ,tipoDocId        : data.TIPO_DOC_ID   
    ,flagExtranjero   : data.EXTRANJERO  === 'Y' ? true : false
    //
    ,contacMail       : data.REMOVE           
    ,contacPhone      : data.REMOVE
    ,contacRut        : data.REMOVE                
    ,contacBirthday   : data.REMOVE
    ,contacFirstName  : data.REMOVE
    ,contacFirstName2 : data.REMOVE
    ,contacLastName   : data.REMOVE
    ,contacLastName2  : data.REMOVE

  })
  //
  this.ciudadListFiltered = this.ciudadList.filter((item) => item.ID_REGION == data.ID_REGION);
  this.comunaListFiltered = this.comunaList.filter((item) => item.ID_CIUDAD == data.ID_CIUDAD);

  this.formCustomer.get('idCiudad').enable();
  this.formCustomer.get('idComuna').enable();
  this.data.type = 'edit'
  if(data.RAZON_SOCIAL != data.CUSTOMER_FANCY_NAME ){
    this.formCustomer.get('flagShowFancyName').setValue(true);
  }

}
//######################################################
//######################################################
selectCustomerClaim(){
  let e= {
     RUT          : this.formCustomer.value.rut
    ,RAZON_SOCIAL : this.formCustomer.value.customerFancyName
  }
  this._dialogRef.close(e);
}
//######################################################
//######################################################
async getCustomerContactType(): Promise<void>{
  var tmp2
  let tmp  = await this._clienteService.getCustomerContactType();

if(this.data.type === 'create'){
///////////////////////////////////  
if(this.data.contact.length > 0) 
{
  let primary = this.data.contact.filter(item => item.COD_CONTACT_TYPE == 'P' )
  if(primary.length > 0){
    this.customerContactType = tmp.contactType.filter(item => item.COD_CONTACT_TYPE != 'P' );
  }
  else{
    this.customerContactType = tmp.contactType;
  }
}
else{
  this.customerContactType = tmp.contactType;
}
/////////////////////////////////////
}
else{
  this.customerContactType = tmp.contactType;
}

}
//######################################################
//######################################################
  ngAfterViewInit(): void {
    this.setInitialValue();
  };
//######################################################
//######################################################
protected setInitialValue(): void {
  this.filteredTipo
    .pipe(take(1), takeUntil(this._onDestroy))
    .subscribe(() => {
      // setting the compareWith property to a comparison function
      // triggers initializing the selection according to the initial value of
      // the form control (i.e. _initializeSelection())
      // this needs to be done after the filteredBanks are loaded initially
      // and after the mat-option elements are available
      // this.singleSelect.compareWith = (a: any, b: any) => a && b && a.ID_COMUNA === b.ID_COMUNA;
      // this.singleSelect.value = this.comunas;
    });
}
//######################################################
//######################################################
filterType(): void {
  if(!this.customerContactType){
    return;
  }
  let search = this.tipoFilterCtrl.value;
  if(!search){
    this.filteredTipo.next(this.customerContactType.slice());
    return;
  }else{
    search = search.toLowerCase();
  }
  this.filteredTipo.next(
    this.customerContactType.filter(item => item.GLS_CONTACT_TYPE.toLowerCase().indexOf(search) > -1)
  );
};
//######################################################
//######################################################
//this._clienteService.getAddressType()
async getAddressType(): Promise<void>{
  var tmp = await this._clienteService.getAddressType();
  this.addressType = tmp.addressType
}  
//######################################################
//######################################################
 async  ngOnInit() {
    await this.getAddressType()
    await this.getLocation(this.data.tab)
    await this.getCustomerValue();
    this.authDTE = await this.makeAuthInvoice(33);
    await this.getDteData();
    //============================================================
      if( this.data.tab === 'customer' && this.data.type === 'create'){
        this.flagSgtm = false;
        //await this.getLocation(this.data.tab)
          /////////////////////////////////////////////////////////////////////////
          this.formCustomer.get('contacMail').setValidators([Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9_.+-]+\.[a-zA-Z0-9_.+-]{2,4}$')]);
          this.formCustomer.get('contacRut').setValidators([Validators.required]);
          this.formCustomer.get('contacFirstName').setValidators([Validators.required]);
          this.formCustomer.get('contacLastName').setValidators([Validators.required]);
  
  /////////////////////////////////////////////////////////////////////////
      }
      if( this.data.tab === 'customer' && this.data.type === 'edit'){
        this.formCustomer.get('rut').setValue(this.data.rut);
        this.chkSgtm()
        this.flagSgtm = true;
      }    
      //==
      if( this.data.tab === 'contact' && this.data.type === 'create'){
        await this.getCustomerContactType();
        this.filteredTipo.next(this.customerContactType.slice());

        this.tipoFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterType();
          });
      }
      //==      
      if( this.data.tab === 'contact' && this.data.type === 'edit'){
        await this.getCustomerContactType();
        this.filteredTipo.next(this.customerContactType.slice());

        this.tipoFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterType();
          });  
          //##
          this.formContact.patchValue({
             uuidCustomer              : this.data.uuidCustomer
            ,appToken                  : [null]
            ,uuidCrmCustomerContact    : this.data.contactDetail.UUID_CRM_CUSTOMER_CONTACT
            ,uuidCrmCustomer           : this.data.uuidCustomer
            ,idCrmCustomerContactType  : this.data.contactDetail.ID_CRM_CUSTOMER_CONTACT_TYPE
            ,mail                      : this.data.contactDetail.MAIL
            ,phone                     : this.data.contactDetail.PHONE
            ,legalNumber               : this.data.contactDetail.LEGAL_NUMBER
            ,firstName                 : this.data.contactDetail.FIRST_NAME
            ,firstName2                : this.data.contactDetail.FIRST_NAME2
            ,lastName                  : this.data.contactDetail.LAST_NAME
            ,lastName2                 : this.data.contactDetail.LAST_NAME2
            ,extraData                 : this.data.contactDetail.EXTRA_DATA
            ,birthday                  : this.data.contactDetail.BIRTHDAY
          })
          this.formContact.get('idCrmCustomerContactType').disable();
          //this.formContact.get('legalNumber').disable();
          //##
      }   
      //==       
              
    //============================================================
    if(this.data.type === 'create' && this.data.tab === 'notas'){
      this.notasForm.get('dateSchedule').disable();
    }else if(this.data.type === 'update' && this.data.tab === 'notas'){
      this.getNotesByUUID();
    } else if(this.data.type === 'create' && this.data.tab === 'direcciones'){
      //await this.getLocation(this.data.tab);

      this.formAddress.get('idRegion').valueChanges.subscribe((value) => {
        if(value && this.ciudadList.length >0){
          this.ciudadListFiltered = this.ciudadList.filter((item) => item.ID_REGION == value);
          // console.log(this.ciudadList)
        }
      });

      this.formAddress.get('idCiudad').valueChanges.subscribe((value) => {
        if(value && this.comunaList.length > 0){
          this.comunaListFiltered = this.comunaList.filter((item) => item.ID_CIUDAD == value);
        }
      });
    } else if( this.data.type === 'update' && this.data.tab === 'direcciones'){
      //await this.getLocation(this.data.tab);

      this.formAddress.get('idRegion').valueChanges.subscribe((value) => {
        if(value && this.ciudadList.length > 0){
          this.ciudadListFiltered = this.ciudadList.filter((item) => item.ID_REGION == value);
          // console.log(this.ciudadList)
        }
      });

      this.formAddress.get('idCiudad').valueChanges.subscribe((value) => {
        if(value && this.comunaList.length >0){
          this.comunaListFiltered = this.comunaList.filter((item) => item.ID_CIUDAD == value);
        }
      });
      this.setAddressType(this.data.element.ID_CRM_CUSTOMER_ADDRESS_TYPE)
      this.formAddress.patchValue({
        aliasCustomerAddress: this.data.element.ALIAS_CUSTOMER_ADDRESS,
        address1: this.data.element.ADDRESS1,
        address2: this.data.element.ADDRESS2,
        address3: this.data.element.ADDRESS3,
        phoneNumber: this.data.element.PHONE_NUMBER,
        postalCode: this.data.element.POSTAL_CODE,
        deliveryNotes: this.data.element.DELIVERY_NOTES,
        idRegion: this.data.element.ID_REGION,
        idCiudad: this.data.element.ID_CIUDAD,
        idComuna: this.data.element.ID_COMUNA,
        addressDefault :this.data.element.FLAG_DEFAULT,
        addressType :this.data.element.TYPE_ADDRESS,
        idCrmCustomerAddressType:this.data.element.ID_CRM_CUSTOMER_ADDRESS_TYPE
      });
      
    }

  }
//######################################################
setReminders(e){

    if(e == 'Y'){
      this.notasForm.get('dateSchedule').enable();
      this.notasForm.get('dateSchedule').setValidators([Validators.required]);
      this.notasForm.get('dateSchedule').updateValueAndValidity();
    }else{
      this.notasForm.get('dateSchedule').clearValidators();
      this.notasForm.get('dateSchedule').updateValueAndValidity();
      this.notasForm.get('dateSchedule').disable();
    }

}
//######################################################
  async create(): Promise<void>{
    let tmp =  (JSON.parse(localStorage.getItem('ssoData')))
    var dateSchedule,resp;

   if(this.data.tab === 'notas'){
      //##############################################################
      this.txtError="";
          if(this.notasForm.value.valTpoNota == 'Y'){
                //===============================================================
                if(this.notasForm.value.txt.toString().length < 10) { 
                      this.isDisabledNote = true;
                      this.txtError="Largo minimo del campo nota es de 10 caracteres";   
                  } else {  
                      this.isDisabledNote = false; 
                  } 
                if((this.notasForm.value.dateSchedule).toString().length  > 10) { 
                      dateSchedule = (this.notasForm.value.dateSchedule).toISOString();
                    } else  { 
                      this.isDisabledNote = true;
                      this.txtError=this.txtError+ "<br>Selecionar fecha de recordatorio";   
                    }

                  if(this.isDisabledNote == false){            
                  
                    const data = {
                       uuidCmEmployee     : this.uuidCmEmployee
                      ,txtCustomerNotes   : this.notasForm.value.txt
                      ,dateSchedule       : dateSchedule.split('T')[0]
                      ,flagReminders      : this.notasForm.value.valTpoNota
                      ,uuidCrmCustomer    : this.data.customer.UUID_CRM_CUSTOMER
                    };
                        resp = await this._clienteService.createNote(data);
                      if(resp.status){
                        this._dialogRef.close(true);
                      }
                  }
                //===============================================================
              }
              else{
                //===============================================================
                this.txtError="Largo minimo del campo nota es de 10 caracteres"; 
                if(this.notasForm.value.txt.toString().length < 10) { this.isDisabledNote = true;  } else {  this.isDisabledNote = false; } 
                  if(this.isDisabledNote == false){
                    const data = {
                      uuidCmEmployee     : this.uuidCmEmployee
                    ,txtCustomerNotes   : this.notasForm.value.txt
                    ,dateSchedule       : '9999/12/31'
                    ,flagReminders      : this.notasForm.value.valTpoNota
                    ,uuidCrmCustomer    : this.data.customer.UUID_CRM_CUSTOMER
                  };
                        resp = await this._clienteService.createNote(data);
                      if(resp.status){
                        this._dialogRef.close(true);
                      }
                  }
                //===============================================================
              }
      //##############################################################      
    }else if(this.data.tab === 'direcciones'){
      const data = {                        
        uuidCrmCustomer        : this.data.customer.UUID_CRM_CUSTOMER  
        ,id_region             : this.formAddress.value.idRegion              
        ,idCiudad              : this.formAddress.value.idCiudad
        ,idComuna              : this.formAddress.value.idComuna
        ,aliasCustomerAddress  : this.formAddress.value.aliasCustomerAddress
        ,address1              : this.formAddress.value.address1
        ,address2              : this.formAddress.value.address2
        ,address3              : this.formAddress.value.address3
        ,phoneNumber           : this.formAddress.value.phoneNumber
        ,postalCode            : this.formAddress.value.postalCode
        ,deliveryNotes         : this.formAddress.value.deliveryNotes
        ,flagDefault           : this.formAddress.value.addressDefault
        ,typeAddres            : this.formAddress.value.addressType
        ,idCrmCustomerAddressType:this.formAddress.value.idCrmCustomerAddressType
      };
      const resp = await this._clienteService.createAddress(data);
      if(resp.status){
        this._dialogRef.close(true);
      }
    }
  }
//######################################################
  async update(): Promise<void>{
    let tmp =  (JSON.parse(localStorage.getItem('ssoData')))
    if(this.data.tab === 'direcciones'){
      const data = {                        
         uuidCrmCustomer       : this.data.customer.UUID_CRM_CUSTOMER  
        ,uuidCrmCustomerAddress: this.data.element.UUID_CRM_CUSTOMER_ADDRESS
        ,idRegion              : this.formAddress.value.idRegion              
        ,idCiudad              : this.formAddress.value.idCiudad
        ,idComuna              : this.formAddress.value.idComuna
        ,aliasCustomerAddress  : this.formAddress.value.aliasCustomerAddress
        ,address1              : this.formAddress.value.address1
        ,address2              : this.formAddress.value.address2
        ,address3              : this.formAddress.value.address3
        ,phoneNumber           : this.formAddress.value.phoneNumber
        ,postalCode            : this.formAddress.value.postalCode
        ,deliveryNotes         : this.formAddress.value.deliveryNotes
        ,flagDefault           : this.formAddress.value.addressDefault
        ,typeAddres            : this.formAddress.value.addressType
        ,idCrmCustomerAddressType:this.formAddress.value.idCrmCustomerAddressType
      };
      const resp = await this._clienteService.updateAddress(data);
      if(resp.status){
        this._dialogRef.close(true);
      }
    }
  };
//######################################################
  async getNotesByUUID(): Promise<void>{
    // const { notes } = await this._clienteService.getNotesByID(this.data.customer.UUID_CRM_CUSTOMER, this.data.element.ID_CRM_CUSTOMER_NOTES);
    // console.log(notes[0]);
    this.notasForm.get('txt').setValue(this.data.element.TXT_CUSTOMER_NOTES);
    this.notasForm.get('dateSchedule').setValue(this.data.element.DATE_SCHEDULE);
    this.notasForm.get('flagReminders').setValue(this.data.element.FLAG_REMINDERS === 'Y' ? true : false);
  }
//######################################################
//######################################################

//######################################################
  filterComunaByRegion(): void{

  }
//######################################################
formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('/');
}
//######################################################
async createCustomer(): Promise<void>{
  var contacBirthday,contactRut,rutOK
//preparo Formulario customerFancyName
if(this.formCustomer.value.customerFancyName == null){
  this.formCustomer.get('customerFancyName').setValue(this.formCustomer.value.razonSocial);
}
//==
this.formCustomer.get('contactoDte').setValue(this.formCustomer.value.contacFirstName +' '+this.formCustomer.value.contacLastName)
//==
if(this.formCustomer.value.contacPhone == null){
  this.formCustomer.get('telefonoDte').setValue('56900000000')
  this.formCustomer.get('contacPhone').setValue('56900000000')
}
else{
  this.formCustomer.get('telefonoDte').setValue(this.formCustomer.value.contacPhone)
}
//==
if(this.formCustomer.value.contacBirthday == null){
  contacBirthday = null
}
else{
  contacBirthday = this.formatDate(this.formCustomer.value.contacBirthday   )
}
//==
//#############################################################################
//#############################################################################
//contactRut = rut.formatRut(this.formCustomer.value.contacRut  ,rut.RutFormat.DASH)

this.formCustomer.get('contacRut').enable();
rutOK = this.chkRut(this.formCustomer.value.contacRut)
if(rutOK.status == true){
  //====================
  contactRut=rutOK.srtRutClean
      //==
      let json = {
                      uuidSSO          : this.formCustomer.value.uuidCustomer     
                    ,appToken         : this.formCustomer.value.appToken         
                    ,idCmCustomerValue: this.formCustomer.value.idCmCustomerValue
                    ,flagNewsletter   : this.formCustomer.value.flagNewsletter     ? 'Y' : 'N'
                    ,website          : this.formCustomer.value.website          
                    ,maxPaymentDays   : this.formCustomer.value.maxPaymentDays         
                    ,flagBloqCustomer : this.formCustomer.value.flagBloqCustomer    ? 'Y' : 'N'
                    ,flagBloqSupplier : this.formCustomer.value.flagBloqSupplier    ? 'Y' : 'N'
                    ,flagCustomer     : this.formCustomer.value.flagCustomer        ? 'Y' : 'N'
                    ,flagSupplier     : this.formCustomer.value.flagSupplier        ? 'Y' : 'N'
                    ,flagEmployee     : this.formCustomer.value.flagEmployee        ? 'Y' : 'N'
                    ,flagAssociate    : this.formCustomer.value.flagAssociate       ? 'Y' : 'N'
                    ,flagDistributor  : this.formCustomer.value.flagDistributor     ? 'Y' : 'N'
                    ,creditLimit      : this.formCustomer.value.creditLimit                 
                    ,customerCsat     : this.formCustomer.value.customerCsat     
                    ,rut              : this.formCustomer.value.rut              
                    ,razonSocial      : this.formCustomer.value.razonSocial             
                    ,customerFancyName: this.formCustomer.value.customerFancyName
                    ,giroImpreso      : this.formCustomer.value.giroImpreso      
                    ,direccion        : this.formCustomer.value.direccion        
                    ,idRegion         : this.formCustomer.value.idRegion         
                    ,idCiudad         : this.formCustomer.value.idCiudad         
                    ,idComuna         : this.formCustomer.value.idComuna                          
                    ,emailDte         : this.formCustomer.value.emailDte                         
                    ,contactoDte      : this.formCustomer.value.contactoDte      
                    ,telefonoDte      : this.formCustomer.value.telefonoDte      
                    ,contacMail       : this.formCustomer.value.contacMail                  
                    ,contacPhone      : this.formCustomer.value.contacPhone      
                    ,contacRut        : contactRut                           
                    ,contacBirthday   : contacBirthday
                    ,contacFirstName  : this.formCustomer.value.contacFirstName  
                    ,contacFirstName2 : this.formCustomer.value.contacFirstName2 
                    ,contacLastName   : this.formCustomer.value.contacLastName   
                    ,contacLastName2  : this.formCustomer.value.contacLastName2  

                    ,codNacionalidad  : this.formCustomer.value.codNacionalidad
                    ,tipoDocId        : this.formCustomer.value.tipoDocId
                    ,flagExtranjero   : this.formCustomer.value.flagExtranjero ? 'Y' : 'N'
                    }
      console.log(json)
      let resp = await this._clienteService.createUpdateCustomer(json);
      if(resp.status){
        if(this.data.source == 'claim'){
          this.selectCustomerClaim()
        }else{
          this._dialogRef.close(true);
        }
      }
      //==
  //====================        
  }
else{
  //====================
        const dialogRef = this._fuseConfirmationService.open({
          title: `Error `,
          message: `Rut Contacto no valido: <b>${rut.formatRut(this.formCustomer.value.contacRut, rut.RutFormat.DOTS_DASH)}</b>`,
          icon: {
              show: true,
              name: 'delete',
              color: 'warn'
          },
          actions: {
              confirm: {
                  label: 'Aceptar',
                  color: 'warn'
              },
              cancel: {
                  show : false,
                  label : "Cancel"
                }
          },
          dismissible: true    
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        this.formCustomer.get('contacRut').setValue(null);
      });
  //====================        
}


//#############################################################################
//#############################################################################
}
//######################################################
async updateCustomer(): Promise<void>{

  let json = {
    uuidSSO          : this.formCustomer.value.uuidCustomer     
   ,appToken         : this.formCustomer.value.appToken         
   ,idCmCustomerValue: this.formCustomer.value.idCmCustomerValue
   ,flagNewsletter   : this.formCustomer.value.flagNewsletter     ? 'Y' : 'N'
   ,website          : this.formCustomer.value.website          
   ,maxPaymentDays   : this.formCustomer.value.maxPaymentDays         
   ,flagBloqCustomer : this.formCustomer.value.flagBloqCustomer    ? 'Y' : 'N'
   ,flagBloqSupplier : this.formCustomer.value.flagBloqSupplier    ? 'Y' : 'N'
   ,flagCustomer     : this.formCustomer.value.flagCustomer        ? 'Y' : 'N'
   ,flagSupplier     : this.formCustomer.value.flagSupplier        ? 'Y' : 'N'
   ,flagEmployee     : this.formCustomer.value.flagEmployee        ? 'Y' : 'N'
   ,flagAssociate    : this.formCustomer.value.flagAssociate       ? 'Y' : 'N'
   ,flagDistributor  : this.formCustomer.value.flagDistributor     ? 'Y' : 'N'
   ,creditLimit      : this.formCustomer.value.creditLimit                 
   ,customerCsat     : this.formCustomer.value.customerCsat     
   ,rut              : this.formCustomer.value.rut              
   ,razonSocial      : this.formCustomer.value.razonSocial             
   ,customerFancyName: this.formCustomer.value.customerFancyName
   ,giroImpreso      : this.formCustomer.value.giroImpreso      
   ,direccion        : this.formCustomer.value.direccion        
   ,idRegion         : this.formCustomer.value.idRegion         
   ,idCiudad         : this.formCustomer.value.idCiudad         
   ,idComuna         : this.formCustomer.value.idComuna                          
   ,emailDte         : this.formCustomer.value.emailDte                         
   ,contactoDte      : this.formCustomer.value.contactoDte      
   ,telefonoDte      : this.formCustomer.value.telefonoDte     
   ,codNacionalidad  : this.formCustomer.value.codNacionalidad
   ,tipoDocId        : this.formCustomer.value.tipoDocId
   ,flagExtranjero   : this.formCustomer.value.flagExtranjero ? 'Y' : 'N'    
}
let resp = await this._clienteService.createUpdateCustomer(json);
if(resp.status){
this._dialogRef.close(true);
}


}
//######################################################
btnClose(){
  this._dialogRef.close();
}
//######################################################
async createContact(){
  var contacBirthday,contactRut,rutOK
if(this.formContact.value.legalNumber == null || this.formContact.value.legalNumber == undefined ) {
  this.formContact.value.legalNumber='11111111-1'
}
  rutOK = this.chkRut(this.formContact.value.legalNumber,true)
  if(rutOK.status == true){
    //====================
    contactRut = rutOK.srtRutClean

    
    if(this.formContact.value.birthday == null){
      contacBirthday = null
    }
    else{
      contacBirthday = this.formatDate(this.formContact.value.birthday   )
    }
    let arr = {
                   appToken                  : null
                  ,language                  : 'es'     
                  ,uuidCustomer              : this.data.uuidCustomer
                  ,uuidCrmCustomerContact    : null
                  ,idCrmCustomerContactType  : this.formContact.value.idCrmCustomerContactType
                  ,mail                      : this.formContact.value.mail
                  ,phone                     : this.formContact.value.phone
                  ,legalNumber               : contactRut
                  ,firstName                 : this.formContact.value.firstName
                  ,firstName2                : this.formContact.value.firstName2
                  ,lastName                  : this.formContact.value.lastName
                  ,lastName2                 : this.formContact.value.lastName2
                  ,extraData                 : this.formContact.value.extraData
                  ,birthday                  : contacBirthday
              } 
              let resp = await this._clienteService.newCustomerContact(arr);
              if(resp.success == true){
                this._dialogRef.close(true);
                }
    //====================        
    }
  else{
    //====================
          const dialogRef = this._fuseConfirmationService.open({
            title: `Error `,
            message: `Rut Contacto no valido: <b>${rut.formatRut(this.formContact.value.legalNumber, rut.RutFormat.DOTS_DASH)}</b>`,
            icon: {
                show: true,
                name: 'delete',
                color: 'warn'
            },
            actions: {
                confirm: {
                    label: 'Aceptar',
                    color: 'warn'
                },
                cancel: {
                    show : false,
                    label : "Cancel"
                  }
            },
            dismissible: true    
        });
        dialogRef.afterClosed().subscribe(async (result) => {
          this.formContact.get('legalNumber').setValue(null);
        });
    //====================        
  }
}
//######################################################
async updateContact(){
  var contacBirthday,contactRut,rutOK
if(this.formContact.value.legalNumber == null || this.formContact.value.legalNumber == undefined || this.formContact.value.legalNumber == '') {
  this.formContact.value.legalNumber='11111111-1'
}
  rutOK = this.chkRut(this.formContact.value.legalNumber,true)
  if(rutOK.status == true){
    //====================
    contactRut = rutOK.srtRutClean

    
    if(this.formContact.value.birthday == null){
      contacBirthday = null
    }
    else{
      contacBirthday = this.formatDate(this.formContact.value.birthday   )
    }
              let arr = {
                appToken                  : null
              ,language                  : 'es'     
              ,uuidCustomer              : this.data.uuidCustomer
              ,uuidCrmCustomerContact    : this.formContact.value.uuidCrmCustomerContact
              ,mail                      : this.formContact.value.mail
              ,phone                     : this.formContact.value.phone
              ,legalNumber               : contactRut
              ,firstName                 : this.formContact.value.firstName
              ,firstName2                : this.formContact.value.firstName2
              ,lastName                  : this.formContact.value.lastName
              ,lastName2                 : this.formContact.value.lastName2
              ,extraData                 : this.formContact.value.extraData
              ,birthday                  : contacBirthday
              ,remove                     : 'N'
          } 
          let resp = await this._clienteService.udpCustomerContact(arr);
          if(resp.success == true){
            this._dialogRef.close(true);
            }
    //====================        
    }
  else{
    //====================
          const dialogRef = this._fuseConfirmationService.open({
            title: `Error `,
            message: `Rut Contacto no valido: <b>${rut.formatRut(this.formContact.value.legalNumber, rut.RutFormat.DOTS_DASH)}</b>`,
            icon: {
                show: true,
                name: 'delete',
                color: 'warn'
            },
            actions: {
                confirm: {
                    label: 'Aceptar',
                    color: 'warn'
                },
                cancel: {
                    show : false,
                    label : "Cancel"
                  }
            },
            dismissible: true    
        });
        dialogRef.afterClosed().subscribe(async (result) => {
          this.formContact.get('legalNumber').setValue(null);
        });
    //====================        
  }
/*
  var contacBirthday  
    if(this.formContact.value.birthday == null){
      contacBirthday = null
    }
    else{
      contacBirthday = this.formatDate(this.formContact.value.birthday   )
    }
    let arr = {
                   appToken                  : null
                  ,language                  : 'es'     
                  ,uuidCustomer              : this.data.uuidCustomer
                  ,uuidCrmCustomerContact    : this.formContact.value.uuidCrmCustomerContact
                  ,mail                      : this.formContact.value.mail
                  ,phone                     : this.formContact.value.phone
                  ,firstName                 : this.formContact.value.firstName
                  ,firstName2                : this.formContact.value.firstName2
                  ,lastName                  : this.formContact.value.lastName
                  ,lastName2                 : this.formContact.value.lastName2
                  ,extraData                 : this.formContact.value.extraData
                  ,birthday                  : contacBirthday
                  ,remove                     : 'N'
              } 
              let resp = await this._clienteService.udpCustomerContact(arr);
              if(resp.success == true){
                this._dialogRef.close(true);
                }
    //====================        
    */
}
//######################################################
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
async getDteData(): Promise<void> {
  (await this._docsServ.getRulesFormatByType(this.authDTE, 0)).subscribe(
    (res) => {
      res = res.dataSet.ruleDte.ruleAgpcn;
      this.nacionalidad  = res.filter(item => item.codAgpcn === '13' );
      this.tipoDocID     = res.filter(item => item.codAgpcn === '23' );
      
    },
    (err) => {
      console.log(err);
    }
  );
  
}
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
async makeAuthInvoice(TipoDTE): Promise<any>{  
  let $this = this;
  return await new Promise(
    async function (resolve, reject) {
      var auth,token
      token = localStorage.getItem('appToken')
        //////////////////////////////////////////// 
        if(TipoDTE == 39 || TipoDTE == 41){
              auth = {"appToken":token,"language":"es","environment": $this.userData.envirometBOL,"user":$this.userData.login, "passwd":null, "pin":$this.userData.pin,"log":false};
        }
        else if(TipoDTE == 5001 ){ // hacer clase para tratar los tkt
              auth = {"appToken":token,"language":"es","environment": $this.userData.envirometTKT,"user":$this.userData.login, "passwd":null, "pin":$this.userData.pin,"log":false};
       }
         else{
              auth = {"appToken":token,"language":"es","environment": $this.userData.envirometDTE,"user":$this.userData.login, "passwd":null, "pin":$this.userData.pin,"log":false};
       }
              ////////////////////////////////////////////  
       resolve(auth)
      }); 
}
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
}

/* eslint-disable @typescript-eslint/prefer-for-of */
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatDatepicker } from '@angular/material/datepicker';
import { split } from 'lodash';
// import { ConfirmChangeDateComponent } from './confirm-change-date/confirm-change-date.component';
import { SalesPointService } from 'app/services/salesPoint.service';
import { WareHouseProductComponent } from '../ware-house-product/ware-house-product.component';
import { ChangeDetectorRef } from '@angular/core';
import { CatalogService } from 'app/services/catalog.service';
//########################
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormBuilder, FormControl } from '@angular/forms';

import { MatDrawer } from '@angular/material/sidenav'
import { psServicesCart } from 'app/model/psServicesCart';
import { customerTable,customer } from 'app/model/customer';
import { MatSelectChange } from '@angular/material/select';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { merge, Observable, of as observableOf, pipe } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {MatTableModule} from '@angular/material/table'; 
//#######################
@Component({
  selector: 'app-dialog-search-products',
  templateUrl: './dialog-search-products.component.html',
  styleUrls: ['./dialog-search-products.component.scss']
})
//===================================================================
//===================================================================
export class DialogSearchProductsComponent implements OnInit {
//############################################################
//############################################################
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('fechaCatalogo') dateCtlg: MatDatepicker<any>;
  displayedColumns: string[] = ['code','name','tipo' ,'priceOrg', 'price','stock','flagStock' ,'add'];
  pageSizeOptions          : any = [ 10, 50, 100]; 
  pagDisplayedColumns    : number = 10;
  dataSource: MatTableDataSource<psServicesCart>;
  tableSource: any = [];
  totalPsServices =0



//############################################################
categories     : any = []
groups         : any = []
groupsSelected : any = []
//############################################################
codCategory : string ='0'
codGroup    : string ='0'
searchTerm  : string ='0'
//############################################################


  isLoading: boolean = false;

  today: Date = new Date();
  
  maxDate: any;
  minDate: any;
  lastDate: Date;
  //
  minDateInfo: Date;
  maxDateInfo: Date;
  minDateRef : Date;
  maxDateRef : Date;
//############################################################
infoForm: UntypedFormGroup;
//############################################################ 
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogSearchProductsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _salesPointService : SalesPointService,
    private _dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private _fb: FormBuilder,
    private _toast : ToastrService,
    private _catalogService: CatalogService,
  ) { 

    this.infoForm = this._fb.group({
      searchSelect   : [null],
      category       : [null],
      group          : [null],
      datePsServices : ['def']
    })
  }

//############################################################
//############################################################
async filterCategory(idPsCategory){
  var chk,category
    this.groupsSelected =  this.groups.filter(item => item.ID_PS_CATEGORY == idPsCategory)
    if( this.groupsSelected.length == 0){
      this.infoForm.get('group').setValue(null);
      this.infoForm.get('group').disable();
    }
    else{
      this.infoForm.get('group').enable();
      chk = this.groupsSelected.filter(item => item.ID_PS_GROUP == this.infoForm.value.group)
      if(chk.length > 0){}
      else{
        this.infoForm.get('group').setValue(null);
      }
    }
    //===================================
    category          = await this.categories.filter(i => i.ID_PS_CATEGORY == idPsCategory)[0]
     this.codCategory = category.COD_PS_CATEGORY
     this.codGroup    = '0'
    this.ngAfterViewInit()
    //===================================
  }
//############################################################
//############################################################
  async searchByTxt(e?:Event): Promise<any>{  

    var s,tmp
    const regex = / /gi;
    tmp = this.infoForm.value.searchSelect 
    s = tmp.replace(regex,'&')
    if( s == null || s == undefined || s == ''){
      s='0'
    }
    this.searchTerm = s
    this.ngAfterViewInit() 
    
     };
//############################################################
//############################################################
async filterGroup(idPsGroup){
  var group     = this.groups.filter(item => item.ID_PS_GROUP == idPsGroup)[0]
  this.codGroup = group.COD_PS_GROUP
  this.ngAfterViewInit()
  }
//############################################################
//############################################################
async ngOnInit(): Promise<any>{
  var tmp
  tmp = await this.getAllCategories()
  this.categories = tmp.data;
  tmp = await this.getAllGroups()
  this.groups = tmp.data;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentDay = new Date().getDate();
  this.minDateInfo = new Date(currentYear - 0, currentMonth, currentDay - 7);
  this.maxDateInfo = new Date(currentYear + 0, currentMonth + 3, currentDay);
  this.minDateRef = new Date(currentYear - 21, 11, 0);
  this.maxDateRef = new Date(currentYear + 28, 11, 31);
}
//############################################################
//############################################################
async getAllCategories(): Promise<any>{
  const $this = this;
  return await new Promise(async (resolve, reject) => {
    try {
      const resp = await $this._catalogService.getCategorias();
      resolve({status: true, data: resp.data});
    } catch (err) {
      reject({status: false, error: err});
    }
  });
}
//############################################################
//############################################################
async getAllGroups(): Promise<any>{
  const $this = this;
  return await new Promise(async (resolve, reject) => {
    try {
      const resp = await $this._catalogService.getGroups();
      resolve({status: true, data: resp.data});
    } catch (err) {
      reject({status: false, error: err});
    }
  });
};
//############################################################
//############################################################
spPsServicesPagination$(uuidSp:string,uuidWh:string,date:string,codCategory:string,codGroup:string,page:number,perPage:number,from:string,searchTerm:string,orderBy:string) {
  return this._salesPointService.spPsServicesPagination(uuidSp,uuidWh,date,codCategory,codGroup,page,perPage,from,searchTerm,orderBy)
}
//############################################################
//############################################################
ngAfterViewInit() {  
    this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          return this.spPsServicesPagination$(this.data.uuidSp,this.data.uuidWh,this.infoForm.value.datePsServices,this.codCategory,this.codGroup,this.paginator.pageIndex + 1,this.paginator.pageSize,'0',this.searchTerm,'0').pipe(catchError(() => observableOf(null)));
        }),
        map((rs) => {
         // console.log("####")
         // console.log(rs)
         // console.log("####")
          if (rs == null) return [];
          this.totalPsServices = rs.total;
          this.isLoading = false;
          return rs.data;
        })
      )
      .subscribe((rsData) => {
        this.tableSource = rsData;
        console.log(this.tableSource)
        this.dataSource = new MatTableDataSource(this.tableSource);
      });
     
  }
//############################################################
//############################################################
async changeDate(e){

let date = this.formatDate(e.value,'-')
this.infoForm.get('datePsServices').setValue(date);
this.ngAfterViewInit() 

}
//############################################################
//############################################################
resetFilters(){
  this.infoForm.get('searchSelect').setValue(null);
  this.infoForm.get('category').setValue(null);
  this.infoForm.get('group').setValue(null);
  this.infoForm.get('datePsServices').setValue(null);
  this.codCategory = '0'
  this.codGroup    = '0'
  this.searchTerm  = '0'
  this.ngAfterViewInit() 
}
//############################################################
//############################################################
  close(): void{
    this.dialogRef.close();
  }
//############################################################
//############################################################
  closeAndAdd(element: any): void{
    if(element.QUANTITY_AVAILABLE> 0){
        if(this.lastDate){
          // this.lastDate = new Date(this.lastDate);
          const last =  this.lastDate;
          this.dialogRef.close({... element, DATE_PRODUCT: last});
        }else{
          this.dialogRef.close(element);
        }
    }
    else{
      console.log("Sin Stock")
      this.dialogRef.close(element);
    }
  }
//############################################################
//############################################################
wareHouseStorage(element): void {
  const dialogRef = this._dialog.open(WareHouseProductComponent, {
      width: '100%'
     ,data: element
  });
  dialogRef.afterClosed().subscribe(result => {
      if(!!result){

      }
      console.log('The dialog was closed');

  });
};
//############################################################
//############################################################
formatDate(date,sep) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join(sep);
}
//########################################################### 
//########################################################### 
}
<div class="flex justify-end">
  <button mat-button (click)="close()">
      <mat-icon>close</mat-icon>
  </button>
  <!-- <button mat-button (click)="test()"> TEST </button> -->
</div>
<!-- #################################################  -->
<div class="w-full">
    <form [formGroup]="infoForm">
        <div >
                <mat-form-field class="w-1/4">
                    <mat-label>Categoría</mat-label>
                    <mat-select formControlName="category"  (selectionChange)="filterCategory($event.value)">
                        <mat-option
                        *ngFor="let category of categories"
                        [value]="category.ID_PS_CATEGORY"
                        >
                        {{ category.NAME_PS_CATEGORY }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-1/4">
                    <mat-label>Sub Categoría</mat-label>
                    <mat-select formControlName="group" (selectionChange)="filterGroup($event.value)">
                        <mat-option
                        *ngFor="let group of groupsSelected"
                        [value]="group.ID_PS_GROUP">
                        {{ group.NAME_PS_GROUP }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>                        
                <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                        <mat-label>Buscar</mat-label>
                        <input matInput formControlName="searchSelect"  (input)="searchByTxt($event.target.value)" placeholder="Buscar..." #input >
                        <mat-icon matPrefix> find_in_page </mat-icon> 
                </mat-form-field>

                <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/5">
                    <mat-label>Fecha Tipo de Cambio </mat-label>
                    <input matInput formControlName="datePsServices" [matDatepicker]="fechaTC" [min]="minDateRef" [max]="maxDateRef" [value]="today" (dateChange)="changeDate($event)">
                    <mat-datepicker-toggle matSuffix [for]="fechaTC"></mat-datepicker-toggle>
                    <mat-datepicker #fechaTC  ></mat-datepicker>
                </mat-form-field>

                <button mat-raised-button color="warn" class="ml-2" (click)="resetFilters()">
                    <mat-icon>refresh</mat-icon>
                </button>
        </div>
</form> 
</div>
<!-- #################################################  -->

<!-- 
<div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">

  <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/2">
    <mat-label>Buscar</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Buscar..." #input>
    <mat-icon matPrefix> find_in_page </mat-icon>
</mat-form-field>   

  <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/2">
    <mat-label>Fecha Tipo de Cambio </mat-label>
    <input matInput  [matDatepicker]="fechaTC" [min]="minDateRef" [max]="maxDateRef" [value]="today" (dateChange)="changeDate($event)">
    <mat-datepicker-toggle matSuffix [for]="fechaTC"></mat-datepicker-toggle>
    <mat-datepicker #fechaTC  ></mat-datepicker>
</mat-form-field>
</div>
-->







<div>
  <div class="mat-elevation-z8 w-full h-800">
      <table mat-table #tableRef [dataSource]="dataSource" class="w-full overflow-hidden">
          <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="code" class="">
              <th mat-header-cell *matHeaderCellDef class=""> Codigo </th>
              <td mat-cell *matCellDef="let element" class=""> SKU: <b>{{element.COD_PS_SERVICES}}</b> <br/> EAN13: <b>{{element.BARCODE}}</b> </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef >Nombre</th>
              <td mat-cell *matCellDef="let element" class="">{{element.NAME_PS_SERVICES}}</td>
          </ng-container>

          <ng-container matColumnDef="tipo">
              <th mat-header-cell *matHeaderCellDef class="hidden lg:table-cell">Tipo</th>
              <td mat-cell *matCellDef="let element" class="hidden lg:table-cell">
                  <mat-icon *ngIf="element.COD_WAREHOUSE_TYPE === 'W'" class="text-green-700 icon-size-6">
                      warehouse
                  </mat-icon>
                  <mat-icon *ngIf="element.COD_WAREHOUSE_TYPE === 'V'" class="text-blue-700 icon-size-6">
                      cloud
                  </mat-icon>
                  <mat-icon *ngIf="element.COD_WAREHOUSE_TYPE === 'U'" class="text-blue-700 icon-size-6">
                      all_inclusive
                  </mat-icon>
              </td>
          </ng-container>

          <ng-container matColumnDef="priceOrg">
              <th mat-header-cell *matHeaderCellDef>Precio Original</th>
              <td mat-cell *matCellDef="let element">
                  {{element.PRICE.SYMBOL_CURRENCY}}{{element.PRICE.PRICE | number}}
              </td>
          </ng-container>

          <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef>Precio</th>
              <td mat-cell *matCellDef="let element"><b>${{element.PRICE.PRICE_CLP | number}}</b></td>
          </ng-container>

          <ng-container matColumnDef="stock">
              <th mat-header-cell *matHeaderCellDef class="hidden lg:table-cell">Disponibles</th>
              <td mat-cell *matCellDef="let element" class="hidden lg:table-cell">
                  <div *ngIf="element.FLAG_STOCK == 'Y'">
                        <span *ngIf="element.FLAG_OUT_STOCK == 'Y' "> &nbsp; <b style="color: rgb(5, 181, 14)">{{element.QUANTITY_AVAILABLE}}</b>/{{element.TOTAL_QUANTITY_AVAILABLE}}</span>
                        <span *ngIf="element.FLAG_OUT_STOCK == 'N' "> &nbsp; <b>{{element.QUANTITY_AVAILABLE}}</b>/{{element.TOTAL_QUANTITY_AVAILABLE}}</span>
                  </div>
                  <div *ngIf="element.FLAG_STOCK == 'N'">&nbsp;&nbsp;-</div>
              </td>
          </ng-container>

          <ng-container matColumnDef="add">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                  <button
                      *ngIf="element.QUANTITY_AVAILABLE > 0 || element.FLAG_OUT_STOCK == 'Y'"
                      mat-button
                      color="primary"
                      (click)="closeAndAdd(element)">
                      <mat-icon>playlist_add</mat-icon>
                  </button>
              </td>
          </ng-container>

          <ng-container matColumnDef="flagStock">
              <th mat-header-cell *matHeaderCellDef class="hidden lg:table-cell"></th>
              <td mat-cell *matCellDef="let element" class="hidden lg:table-cell">
                  <button
                      *ngIf="element.FLAG_STOCK == 'Y'"
                      class="min-w-10 min-h-7 h-7 px-2 leading-6"
                      mat-stroked-button
                      (click)="wareHouseStorage(element)">
                      <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
                  </button>
              </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  </div>
  <mat-spinner *ngIf="isLoading" style="margin:0 auto"></mat-spinner>
  <mat-paginator
        #paginator
        [pageSize]="pagDisplayedColumns"
        [length]="totalPsServices"
        showFirstLastButtons
        [pageSizeOptions]="pageSizeOptions" 
        aria-label="Select page of periodic elements"> 
  </mat-paginator>
</div>
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  rutEmpresa = localStorage.getItem('rutEmpresa');
  appToken =  localStorage.getItem('appToken');//environment.appTokenUser;


//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  constructor() { }
//================================================================
/**
 * 
 * @param {*} master 
 * @param {*} child 
 * @returns 
 * 
 * master : [ '33', '34', '46', '52', '56', '61' ]
 * child  : [ '33', '56', '61' ]
 * return true
 */
async  _arrIn(master,child) {
    return new Promise((resolve, reject) =>{
        // ========================================================
          try{
            //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""    
             var value;
             for( let i in child){ 
                 value = master.find(element => element ==child[i])
                 if(value == undefined){
                    resolve(false);
                 }
             }
             resolve(true);
            //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
            }
            catch(e)
            {
              reject(false)
            }
        // ========================================================
        })
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!  
    }
//================================================================

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
}
<div class="w-full  sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- ##########################################################  -->
    <!-- ##########################################################  -->
    <div class="w-full">
        <!-- ##########################################################  -->
        <div class="grid grid-cols-4">       
            <div>
                <h2 class="prose prose-xl">Cotización:<b>{{data.ctz.FRIENLY_EOM_CTZ}}</b></h2>
            </div>
            <div>
                <h2 class="prose prose-xl">Estado:<b>{{data.ctz.GLS_EOM_CTZ_STATUS}}</b></h2>
            </div>
            <div>
                <!-- ## -->
                <div *ngIf="data.ctz.COD_EOM_CTZ_STATUS  == 'OPEN' ">
                    <button mat-raised-button class="payBtn" *ngIf="data.ctz.COD_STATUS_DTE  == 'OK' " (click)="makeOrder(data.ctz,'INVOICED')"> 
                            <mat-icon>done</mat-icon>
                            <div *ngIf="data.ctz.COD_DOC_MAKE_ORDER == '39'">Emitir Orden - Boleta </div>
                            <div *ngIf="data.ctz.COD_DOC_MAKE_ORDER == '41'">Emitir Orden - Boleta Exenta </div>
                            <div *ngIf="data.ctz.COD_DOC_MAKE_ORDER == '33'">Emitir Orden - Factura </div>
                            <div *ngIf="data.ctz.COD_DOC_MAKE_ORDER == '34'">Emitir Orden - Factura Exenta </div>
                            <div *ngIf="data.ctz.COD_DOC_MAKE_ORDER == '110'">Emitir Orden - Factura Exportación </div>
                    </button><br>
                    <button mat-raised-button color="warn"   (click)="anularCtz(data.ctz,'REMOVE')"> <mat-icon>close</mat-icon>Anular Cotización</button>
                </div>  
                <!-- ## -->
            </div>  
            <div>
                <button class="close" mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>         
            </div>
        </div>
        <!-- ##########################################################  -->
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider class="py-2"></mat-divider>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<!-- ================================================================================================================== -->                
<!-- ================================================================================================================== -->                
<mat-tab-group #tabsOrder  [(selectedIndex)]="matTabOrderSelected"  (selectedTabChange)="refreshOrder($event,null)" >
    <!-- ## -->
    <mat-tab label="General" >
        <mat-divider class="py-2"></mat-divider>
        <!--########################################################################-->
                <!--TOTALES:INICIO-->
                <div class="grid grid-cols-2">   
                    <div class="text-gray-700 text-sm mb-4">
                            <div class="flex justify-between">
                                <div class="prose">Fecha de Creación:</div>
                                <div class="prose"><b>{{ data.ctz.DATE_CREATION |  date:'dd-MM-yyyy HH:mm:ss' }}</b></div>
                            </div>
                            <div class="flex justify-between"  *ngIf="data.ctz.DATE_UPDATE !='1900-01-01T00:00:00'">
                                <div class="prose">Fecha de Actualización:</div>
                                <div class="prose"><b>{{ data.ctz.DATE_UPDATE |  date:'dd-MM-yyyy HH:mm:ss' }}</b></div>
                            </div>
                    </div>
                    <div class="text-gray-700 text-sm mb-4">
                            <div class="flex justify-between" >
                                <div class="prose">Fecha de Vencimiento:</div>
                                <div class="prose"><b>{{ data.ctz.DATE_DUE_DAY_TXT  |  date:'dd-MM-yyyy' }}</b></div>
                            </div>

                                                
                    </div>
            </div>
            <!-- // -->
            <div>
                            <!--&&&&&&&&&&&&&&&&&&&-->
                                    <div class="prose" *ngIf="data.ctz.COD_EOM_CTZ_STATUS =='EXPIRED' || data.ctz.COD_EOM_CTZ_STATUS =='REMOVE'">
                                        <mat-icon class="text-red-400 icon-size-5" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                                        <b>Total:<b>{{ data.ctz?.TOTAL  | currency:'CLP':'symbol-narrow'}}</b></b> 
                                    </div>
                                    <div class="prose" *ngIf="data.ctz.COD_EOM_CTZ_STATUS =='INVOICED'">
                                        <mat-icon class="text-green-400 icon-size-5" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                                        <b>Total:<b>{{ data.ctz?.TOTAL  | currency:'CLP':'symbol-narrow'}}</b></b> 
                                    </div> 
                                    <div class="prose" *ngIf="data.ctz.COD_EOM_CTZ_STATUS =='OPEN' || data.ctz.COD_EOM_CTZ_STATUS =='DRAF'">
                                        <mat-icon class="text-yellow-400 icon-size-5" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                                        <b>Total:<b>{{ data.ctz.TOTAL  | currency:'CLP':'symbol-narrow'}}</b></b> 
                                    </div>
                            <!--&&&&&&&&&&&&&&&&&&&-->                            
                        </div>
                        <!-- // -->
                        <div class="grid grid-cols-3"> 
                            <!--&&&&&&&&&&&&&&&&&&&-->      
                            <div>
                                <div class="flex justify-between">
                                    <div class="prose">Neto:</div>
                                    <div class="prose"><b>{{ data.ctz?.TOTAL_NETO  | currency:'CLP':'symbol-narrow' }}</b></div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="prose">IVA:</div>
                                    <div class="prose"><b>{{ data.ctz?.TOTAL_IVA  | currency:'CLP':'symbol-narrow'}}</b></div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="prose">Descuento:</div>
                                    <div class="prose"><b>{{ data.ctz?.TOTAL_DESCUENTO  | currency:'CLP':'symbol-narrow'}}</b></div>
                                </div>                
                            </div>
                            <!--&&&&&&&&&&&&&&&&&&&-->  
                            <div>
                                <div class="flex justify-between">
                                    <div class="prose">Exento:</div>
                                    <div class="prose"><b>{{ data.ctz?.TOTAL_EXENTO  | currency:'CLP':'symbol-narrow'}}</b></div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="prose">Envio:</div>
                                    <div class="prose"><b>{{ data.ctz?.TOTAL_ENVIO  | currency:'CLP':'symbol-narrow'}}</b></div>
                                </div>
                            </div>
                            <!--&&&&&&&&&&&&&&&&&&&-->                                
                        </div> 
                        <br>
                        <!-- // -->
                        <mat-divider class="py-2"></mat-divider>
                        <!-- -->
                        <div class="grid grid-cols-2"> 
                            <!--&&&&&&&&&&&&&&&&&&&--> 
                            <div>


                                <div *ngIf="data.ctz?.RUT == '66666666-6' || data.ctz?.RUT == '55555555-5'" class="prose" >
                                    Cliente:<b>{{data.ctz?.RUT | rut:'DOTS_DASH'}}<br> {{ data.ctz?.RAZON_SOCIAL | titlecase}}</b>
                                    <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button    disabled="true" > <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button> 
                                </div>  
                                <div *ngIf="data.ctz?.RUT != '66666666-6' &&data.ctz?.RUT != '55555555-5'" class="prose" >
                                    Cliente:<b>{{data.ctz?.RUT | rut:'DOTS_DASH'}}<br> {{ data.ctz?.RAZON_SOCIAL | titlecase}}</b>
                                    <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button                    (click)="editOrCreateClient(data.ctz?.UUID_CRM_CUSTOMER)" > <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button> 
                                </div> 



                            </div>
                            <div>              
                                <div class="prose" >
                                    Colaborador: <b>{{data.ctz.EMPLOYEE_LOGIN || '1-9' | rut:'DOTS_DASH'}} {{data.ctz?.EMPLOYEE_FIRST_NAME}} {{data.ctz?.EMPLOYEE_LAST_NAME}}</b>
                                </div>                                             
                            </div>            
                            <!--&&&&&&&&&&&&&&&&&&&--> 
                        </div>     
                        <!-- // -->  
                        <div class="grid grid-cols-2" *ngIf="data.ctz.CUSTOMER_CONTACT_LEGAL_NUMBER !='1-9'"> 
                            <!--&&&&&&&&&&&&&&&&&&&--> 
                            <div  >
                                <div class="prose" >
                                    Contacto:<b>
                                                {{data.ctz?.CUSTOMER_CONTACT_LEGAL_NUMBER | rut:'DOTS_DASH'}} {{ data.ctz?.CUSTOMER_CONTACT_FIRST_NAME | titlecase}} {{ data.ctz?.CUSTOMER_CONTACT_LAST_NAME | titlecase}}
                                            </b>
                                </div>  
                            </div>
                            <div>    
                                <div class="prose" >
                                        Cargo: {{ data.ctz?.CUSTOMER_CONTACT_GLS_CONTACT_TYPE | titlecase}}<br>
                                        Correo: {{ data.ctz?.CUSTOMER_CONTACT_MAIL | titlecase}}  
                                </div>                                    
                            </div>            
                            <!--&&&&&&&&&&&&&&&&&&&--> 
                        </div>                        

                        <!--TOTALES:FIN-->      
        <!--########################################################################-->
        <mat-divider class="py-2"></mat-divider>
   </mat-tab>
   <!-- ## -->
    <mat-tab label="Productos" >
        <mat-divider class="py-2"></mat-divider>
         <!--########################################################################-->
                <!--TABLA PRODUCTOS:INICIO-->
                <div>
                    <mat-table [dataSource]="orderDetail" class="mat-elevation-z8">
                        <!--//-->                    
                        <ng-container matColumnDef="sku">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">SKU/BAR</mat-header-cell>
                            <mat-cell *matCellDef="let orderDetail">
                                                                        <div>
                                                                            <b>{{orderDetail.COD_PS_SERVICES}}</b>
                                                                            <br>
                                                                            {{orderDetail.BARCODE}}
                                                                        </div>
                            </mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="producto">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Producto</mat-header-cell>
                            <mat-cell *matCellDef="let orderDetail">{{orderDetail.NAME_PS_SERVICES}}</mat-cell>
                        </ng-container>
                        <!--//-->         
                        <ng-container matColumnDef="wareHouse">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Bodega</mat-header-cell>
                            <mat-cell *matCellDef="let orderDetail">
                                                                    <!-- ####  -->
                                                                    <div *ngIf="orderDetail.COD_WAREHOUSE_TYPE === 'W'"><mat-icon  class="text-green-700 icon-size-5"> warehouse </mat-icon>   </div> 
                                                                    <div *ngIf="orderDetail.COD_WAREHOUSE_TYPE === 'V'"><mat-icon  class="text-blue-700 icon-size-5"> cloud </mat-icon>        </div> 
                                                                    <div *ngIf="orderDetail.COD_WAREHOUSE_TYPE === 'U'"><mat-icon  class="text-blue-700 icon-size-5"> all_inclusive </mat-icon></div> 
                                                                    <!-- ####  -->                                                                                
                                                                    <small>{{orderDetail.COD_WAREHOUSE}}-{{orderDetail.NAME_WAREHOUSE}}</small>
                            </mat-cell>
                        </ng-container>
                        <!--//-->                                   
                        <ng-container matColumnDef="cantidad">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Cantidad</mat-header-cell>
                            <mat-cell *matCellDef="let orderDetail">{{orderDetail.QUANTITY_PS_SERVICES}}</mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="precio">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Precio</mat-header-cell>
                            <mat-cell *matCellDef="let orderDetail">
                                                               <div *ngIf="orderDetail.COD_CURRENCY == 'CLP'">
                                                                    {{orderDetail.NETO  | currency:'CLP':'symbol-narrow'}}
                                                               </div>     
                                                               <div *ngIf="orderDetail.COD_CURRENCY != 'CLP'">
                                                                {{orderDetail.NETO  | currency:'CLP':'symbol-narrow'}}
                                                                <br>
                                                                {{orderDetail.SYMBOL_CURRENCY}}{{orderDetail.SALES_PRICE | number}}
                                                                <br>
                                                                <small>
                                                                    TC: ${{orderDetail.CURRENCY_VALUE | number  }} ({{orderDetail.DATE_CURRENCY | date: 'dd-MM-yy'}})
                                                                </small>                                                                
                                                               </div>                                                                    
                            </mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="desc">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Descuentos</mat-header-cell>
                            <mat-cell *matCellDef="let orderDetail">{{orderDetail.DISCOUNT  | currency:'CLP':'symbol-narrow'}}</mat-cell>
                        </ng-container>
                        <!--//-->   
                        <ng-container matColumnDef="promotion">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Promoción</mat-header-cell>
                            <mat-cell *matCellDef="let orderDetail">
                                                                    <div *ngIf="orderDetail.DESC_PS_SERVICES_PROMOTION != '0'">{{orderDetail.DESC_PS_SERVICES_PROMOTION}}</div>
                                                                    <div *ngIf="orderDetail.DESC_PS_SERVICES_PROMOTION == '0'">S/Promoción</div>
                                                                    </mat-cell>
                        </ng-container>
                        <!--//--> 
                        <ng-container matColumnDef="total">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Total</mat-header-cell>
                            <mat-cell *matCellDef="let orderDetail">{{orderDetail.TOTAL  | currency:'CLP':'symbol-narrow'}}</mat-cell>
                        </ng-container>
                        <!--//-->                    
                        <mat-header-row *matHeaderRowDef="columnsDetail" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                        <mat-row *matRowDef="let row;columns: columnsDetail" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                        <!--//-->                    
                    </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoading" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagDetail
                        [pageSize]="orderDetailPag"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>               
                        <!--//-->                
                </div>                
                <!--TABLA PRODUCTOS:FIN-->  
         <!--########################################################################-->
         <mat-divider class="py-2"></mat-divider>
    </mat-tab>
    <!-- ## -->
    <mat-tab label="Cupones Descuento" >
        <mat-divider class="py-2"></mat-divider>
         <!--########################################################################-->
                <!--TABLA DESCUENTOS:INICIO-->
                <div>
                    <mat-table [dataSource]="orderDiscountCoupon" class="mat-elevation-z8">
                        <!--//-->                    
                        <ng-container matColumnDef="cod">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Codigo</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.COD_EPC_DISCOUNT_COUPON}}</mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="amount">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Monto</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                                            <div *ngIf="o.TYPE_EPC_DISCOUNT_COUPON == 'A'"     > <b>{{o.AMOUNT_DISCOUNT_COUPON  | currency:'CLP':'symbol-narrow'}}</b> </div>
                                                            <div *ngIf="o.TYPE_EPC_DISCOUNT_COUPON == 'P'"     > <b>{{o.AMOUNT_DISCOUNT_COUPON  | percent }}</b> </div>                                                            
                                                            
                            </mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="paymean">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Aplica</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                    <div *ngIf="o.FLAG_PM_PAY_MEANS == 'Y'"     > {{o.NAME_PM_PAY_MEANS}} </div>
                                    <div *ngIf="o.FLAG_EPC_SALES_CHANNEL == 'Y'"> {{o.NAME_SALES_CHANNEL}}</div>
                                    <div *ngIf="o.FLAG_PM_PAY_MEANS == 'N'"     > Todo Medio Pago </div>
                                    <div *ngIf="o.FLAG_EPC_SALES_CHANNEL == 'N'"> Todos los canales de Venta</div>                                    
                            </mat-cell>
                        </ng-container>
                        <!--//-->                    
                        <ng-container matColumnDef="reason">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Razon</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.NAME_COD_REASON_CODE}}</mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="obs">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Detalle</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                <div *ngIf="o.OBS != '0' " > {{o.OBS}}</div>
                                <div *ngIf="o.OBS == '0' " > s/i</div>
                            </mat-cell>
                        </ng-container>
                        <!--//-->                                       
                        <mat-header-row *matHeaderRowDef="columnsDiscountCoupon" class="bg-gray-100 border-b border-gray-200" ></mat-header-row>
                        <mat-row *matRowDef="let row;columns: columnsDiscountCoupon" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                    </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoading" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagDiscountCoupon
                        [pageSize]="orderDiscountCouponPag"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>               
                        <!--//-->                
                </div>
                <!--TABLA DESCUENTOS:FIN-->         
         <!--########################################################################-->
         <mat-divider class="py-2"></mat-divider>
    </mat-tab>
    <!-- ## -->
    <mat-tab label="Historial" >
        <mat-divider class="py-2"></mat-divider>
        <!--########################################################################-->
                <!--NOTAS:INICIO--> 
                <div>
                        <mat-table [dataSource]="orderMessage" class="mat-elevation-z8">
                        <!--//-->  
                            <ng-container matColumnDef="employeeName">
                            <mat-header-cell *matHeaderCellDef  class="font-bold uppercase text-sm text-gray-600"> Colaborador </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.EMPLOYEE_DATA.FIRST_NAME}} {{element.EMPLOYEE_DATA.LAST_NAME}} </mat-cell>
                            </ng-container>
                        <!--//-->                      
                            <ng-container matColumnDef="message">
                            <mat-header-cell *matHeaderCellDef  class="font-bold uppercase text-sm text-gray-600"> Mensaje </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.TXT_CTZ_MESSAGE}} </mat-cell>
                            </ng-container>
                        <!--//-->                          
                            <ng-container matColumnDef="date">
                            <mat-header-cell *matHeaderCellDef  class="font-bold uppercase text-sm text-gray-600"> Fecha </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.DATE_CREATION |  date:'dd-MM-yyyy HH:mm:ss'}} </mat-cell>
                            </ng-container>
                        <!--//-->  
                        <ng-container matColumnDef="tipo">
                            <mat-header-cell *matHeaderCellDef  class="font-bold uppercase text-sm text-gray-600"> Tipo </mat-header-cell>
                            <mat-cell *matCellDef="let element"> 
                                <div *ngIf=" element.FLAG_EOM_CTZ_MESSAGE_PUBLIC == 'Y' ">Publica</div>
                                <div *ngIf=" element.FLAG_EOM_CTZ_MESSAGE_PUBLIC == 'N' ">Privada</div>
                            </mat-cell>
                            </ng-container>                                    
                        <!--//-->                                                                 
                            <!-- Columnas de la tabla -->
                            <mat-header-row *matHeaderRowDef="columnsMessage" class="bg-gray-100 border-b border-gray-200" ></mat-header-row>
                            <mat-row *matRowDef="let row;columns: columnsMessage" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>                            
                        </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoading" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagMessage
                        [pageSize]="orderMessagePag"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>  
                </div>                
                <!--NOTAS:FIN-->        
        <!--########################################################################-->
        <mat-divider class="py-2"></mat-divider>
   </mat-tab>      
    <!-- ## -->  
</mat-tab-group>
<!-- ================================================================================================================== -->                
<!-- ================================================================================================================== -->                
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider class="py-2"></mat-divider>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->                                              
<!-- ##########################################################  -->
<!-- ##########################################################  -->
</div>
<!-- ##########################################################  -->
<!-- ##########################################################  -->
</div>